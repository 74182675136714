<template>
  <div style="margin-bottom: 20px">
    <div v-if="!bLoading">
      <v-data-table
        v-if="!isMobile"
        mobile-breakpoint="0"
        :headers="headers"
        :disable-sort="true"
        :loading="bLoadingTable"
        :items="transactions"
        :items-per-page="iItemsPerPage"
        :hide-default-footer="true"
        loading-text="Cargando datos..."
        no-data-text="No hay información disponible para mostrar."
        :footer-props="{
          'items-per-page-text': 'Filas por página',
          'items-per-page-all-text': 'Todos',
        }"
        class="global-table"
      >
        <template slot="progress">
          <v-progress-linear
            color="var(--primary-color-loading-table)"
            indeterminate
          >
          </v-progress-linear>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="(item, i) in transactions"
              :key="item.sFolio"
              class="table-tr-custom"
            >
              <td class="text-left table-td-custom">
                <p class="table-item-text-custom">
                  {{ i + 1 }}
                </p>
              </td>
              <td class="text-left table-td-custom">
                <p class="table-item-text-custom">
                  {{ item.sAccountName }}
                </p>
              </td>
              <td class="text-left table-td-custom">
                <p class="table-item-text-custom">
                  {{ item.tTransactionDate }}
                </p>
              </td>
              <td class="text-left table-td-custom">
                <p class="table-item-text-custom">
                  {{ item.sDescription }}
                </p>
              </td>
              <td class="text-left table-td-custom">
                <p class="table-item-text-custom">
                  {{ item.sTransaction }}
                </p>
              </td>
              <td class="text-center table-td-custom">
                <p
                  :class="
                    item.sTransaction == 'Ingreso'
                      ? 'color-text-green'
                      : 'color-text-red'
                  "
                  class="table-item-text-custom"
                >
                  $
                  {{
                    formatMoneyGlobal(item.dAmount) + " " + item.sCurrencyCode
                  }}
                </p>
              </td>
            </tr>
            <tr v-if="transactions.length === 0">
              <td :colspan="headers.length" class="text-center text-data-empty">
                No hay información disponible para mostrar.
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div v-else>
        <div class="content-table-mobile">
          <div>
            <div v-if="transactions == 0" class="content-empty-table">
              <span class="text-empty-table">
                No hay información disponible para mostrar.
              </span>
            </div>
            <div
              id="TableMobileOC"
              v-for="(itemTable, index) in transactions"
              :key="index"
            >
              <v-container>
                <div class="content-all">
                  <div class="content-left">
                    <p>#</p>
                  </div>
                  <div class="content-right">
                    <p class="text-right">
                      {{ index + 1 }}
                    </p>
                  </div>
                </div>
                <div class="content-all">
                  <div class="content-left">
                    <p>Cuenta</p>
                  </div>
                  <div class="content-right">
                    <p class="text-right">
                      {{ itemTable.sAccountName }}
                    </p>
                  </div>
                </div>
                <div class="content-all">
                  <div class="content-left">
                    <p>Fecha</p>
                  </div>
                  <div class="content-right">
                    <p class="text-right">
                      {{ itemTable.tTransactionDate }}
                    </p>
                  </div>
                </div>
                <div class="content-all">
                  <div class="content-left">
                    <p>Descripción</p>
                  </div>
                  <div class="content-right">
                    <p class="text-right">
                      {{ itemTable.sDescription }}
                    </p>
                  </div>
                </div>
                <div class="content-all">
                  <div class="content-left">
                    <p>Transacción</p>
                  </div>
                  <div class="content-right">
                    <p class="text-right">
                      {{ itemTable.sTransaction }}
                    </p>
                  </div>
                </div>
                <div class="content-all">
                  <div class="content-left">
                    <p>Cantidad</p>
                  </div>
                  <div class="content-right">
                    <p class="expenses-color text-right">
                      $
                      {{
                        formatMoneyGlobal(itemTable.dAmount) +
                        itemTable.sCurrencyCode
                      }}
                    </p>
                  </div>
                </div>
              </v-container>
              <v-divider class="divider-global mt-3 mb-3"></v-divider>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span v-else>
      <v-layout style="height: 100%" row wrap align-center justify-center>
        <loading-animation></loading-animation>
      </v-layout>
    </span>
  </div>
</template>
<script>
export default {
  data: () => ({
    bLoading: false,
    bLoadingTable: false,
    iCurrentPage: 1,
    iPageNumber: 1,
    iItemsPerPage: 30,
    numPages: 0,
    totalItems: 0,
    totalView: 0,
    items: [],
    headers: [
      {
        text: "#",
        sortable: false,
        align: "left",
        value: "sFolio",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Cuenta",
        sortable: false,
        align: "left",
        value: "sAccountName",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Fecha",
        sortable: false,
        align: "left",
        value: "tTransactionDate",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Descripción",
        sortable: false,
        align: "left",
        value: "sDescription",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Transacción",
        sortable: false,
        align: "left",
        value: "sTransaction",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Cantidad",
        sortable: false,
        align: "center",
        value: "tCreatedAt",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
    ],
    aMenuAccion: [
      {
        text: "Detalle",
        icon: "mdi-eye",
        color: "black !important",
      },
    ],
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    updateFooterParams(e) {
      this.iPageNumber = e.iCurrentPage;
      this.iCurrentPage = e.iCurrentPage;
      this.iItemsPerPage = e.iItemsPerPage;
      this.getItems();
    },
  },
  watch: {
    sSearch() {
      this.getItems();
    },
    tStartDate() {
      this.getItems();
    },
    tEndDate() {
      this.getItems();
    },
  },
  components: {
    LoadingAnimation: () =>
      import(
        "../../global_components/loading-animations/loading-animation-one.vue"
      ),
  },
  props: {
    transactions: Array,
    sSearch: String,
    tStartDate: String,
    tEndDate: String,
  },
};
</script>
<style scoped>
.content-all-first {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.content-left-first {
  width: 50%;
  display: flex;
  align-items: center;
  font-family: "pop-Bold";
  font-size: 18px;
}

.content-empty-table {
  padding: 10px 15px 0px 15px;
  text-align: center;
}

.text-empty-table {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
}

.table-item-text-custom {
  color: var(--primary-color-text);
  font-family: "pop-Light";
  font-size: 14px !important;
}

.table-td-custom {
  vertical-align: bottom;
}

.expenses-color {
  color: #e85353 !important;
  font-weight: bold;
}
</style>

<style scoped>
/*#region CSS GLOBAL  */
.content-all-first {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
  margin-bottom: 10px;
}

.content-all {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
}

.content-left-first {
  width: 50%;
  display: flex;
  align-items: center;
  font-family: "pop-Bold";
  font-size: 18px;
}

.content-left {
  width: 50%;
  font-family: "pop-Semibold";
  font-size: 14px;
}

.text-right {
  /* white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden; */
}

.content-right-first {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
  position: relative !important;
}

.content-right {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
}

.content-right {
  overflow: auto;
}

.content-right::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.content-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

.content-table-mobile {
  background-color: var(--primary-color-background-table) !important;
  box-shadow: 0px 0px 10px 5px var(--primary-color-border-shadow) !important;
  border-radius: 10px;
}

.content-title {
  text-align: start;
}

.text-head {
  color: var(--primary-color-text) !important;
}

.content-text {
  /* text-align: end; */
}

.text-response {
  color: var(--primary-color-text) !important;
}

.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}

.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.content-empty-table {
  padding: 10px 15px 0px 15px;
  text-align: center;
}

.text-empty-table {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
}

/*#endregion CSS GLOBAL  */

/*#region CSS RAW MATERIAL */

/*#region CSS SKEYCOLOR */
.content-color-id-expenses {
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.color-expenses {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid var(--primary-color-text);
}

/*#endregion CSS SKEYCOLOR */

/*#region CSS VARIATION */
.content-chip-difference {
  display: flex;
  justify-content: end;
  width: 100%;
}

.chip-difference {
  align-content: center;
  width: 120px;
  text-align: center !important;
  justify-content: center;
  display: flex;
  font-family: "pop-Bold";
}

.content-text-icon-variation {
  display: flex;
  width: 120px;
}

.content-icon-variation {
  width: auto !important;
}

.text-variation {
  width: 100% !important;
  text-align: end !important;
  align-self: center !important;
}

/*#endregion CSS VARIATION */

/*#endregion CSS RAW MATERIAL */
</style>
<style scoped>
.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}

.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
</style>
